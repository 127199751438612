import { PHONE } from "../../../data/common";
import { maskPhone } from "../../../utils";
import css from './Footer.module.scss'
import global from '../../../styles/Global.module.scss'
import Link from 'next/link'
import {useEffect, useState} from "react";
import {getText} from "../../../api/footer";
import {types} from "../../../actions/feedback";
import {useDispatch} from "react-redux";

const Footer = ({isMainPage}) => {

    const dispatch = useDispatch()

    const [text, setText] = useState('')
    const [currentYear, setCurrentYear] = useState()

    useEffect(() => {
        getText().then((data) => setText(data.text))
        setCurrentYear(new Date().getFullYear())
    }, [])

    return (
        <footer className={css.footer} style={isMainPage ? {position: 'relative', top: '-2px', marginBottom: '-2px'} : {}}>
            <div className={`${global.section} ${css.content}`}>
                <div className={css.item}>
                    <div className={css.contacts}>
                        <a href={`tel:${PHONE}`} className={css.phone}>{maskPhone(PHONE)}</a>
                        <p className={css.address}>Центральный офис продаж 125009, г. Москва, Дмитровское шоссе, 73Б</p>
                        <div className={css.develop}>
                            <Link href={"https://www.ddplanet.ru/"}><a target="_blank" rel="noreferrer">Сайт сделали</a></Link> <span className={css.ddp}>DD Planet</span>
                        </div>
                    </div>
                </div>
                <div className={css.item}>
                    <div className={css.cell}>
                        <a href={'https://www.granelle.ru/'} className={css.granelle} target={'_blank'}>Logo Granelle</a>
                        <div className={css.links}>
                            <Link href={"/politics"}><a target="_blank" rel={"noreferrer"} className={css.link}>Политика конфиденциальности</a></Link>
                            <Link href={"/publichnaya-oferta"}><a target="_blank" rel={"noreferrer"} className={css.link}>Публичная оферта</a></Link>
                        </div>
                        {text && <div dangerouslySetInnerHTML={{__html: text}}></div>}
                        <p>© ГК Гранель, {currentYear}</p>
                    </div>
                </div>
            </div>
            <button className={'callback-button'} onClick={() => dispatch({type: types.SET_FEEDBACK_POPUP, payload: {type: 'callback'}})}>Заказать обратный звонок</button>
        </footer>
    )
}

export default Footer

import Footer from './footer/Footer'
import Header from "./header/Header";
import CustomSnackbar from "../general/snackbar/CustomSnackbar";

import dynamic from 'next/dynamic'
import {useSelector} from "react-redux";

const DynamicFeedbackModal = dynamic(() => import('../general/modal/FeedbackModal'), {ssr:false})

export default function Layout({isMainPage, isGallery, children, visibility_map, visible_popup = true}) {
    const {isIE} = useSelector(state => state.catalog)

    return (
            <div className='layout'>
                <Header visibility_map={visibility_map} />
                <div className={isGallery ? 'layout__main layout__padding' : 'layout__main'}>{children}</div>
                {visible_popup && <DynamicFeedbackModal isIE={isIE}/>}
                {!isGallery && <Footer isMainPage={isMainPage}/>}
                <CustomSnackbar/>
            </div>
    )
}


export const isClientSide = () => typeof window !== 'undefined'

/**Принимает дату и возвращает "до {дата}. В случае если в параметрах приходит бессрочная,
 * возвращает переданный параметр."
 * */
export const getCurrentDateText = (date) => {
    return date.toLowerCase() === 'бессрочная' ? date : `до ${date}`
}


/**
 * Считает ширину скроллбара браузера в пикселях
 * @returns {number}
 */
export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;

}

/**
 * Определяет Touch устройство
 * @returns {boolean}
 */

export const isTouchDevice = () => {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

/**
 *
 * @param number
 * @returns {*}
 */
export const flipArray = (number) => {
  try {
    return number.split("_").sort((a, b) => {
      if(a > b) return -1
      if(a < b) return 1
    }).join("/")
  } catch (_) {
    return number
  }
};

export const getGroupsFromSvg = (svg) => {
  const group = svg.current.querySelectorAll('g')
  return Array.from(group).filter(item => {
    if (item.hasAttribute('id')) {
      const id = item.getAttribute('id')
      const reg = /^_/
      if (reg.test(id)) {
        return item
      }
    }
  })
}
/**
 * Проверяет наличие свободных мест на этажах и возвращает первый найденый этаж с местами
 * */
export const getLevelAvailabilityPlace = (info = []) => {
  for (const floor of info) {
    if (floor.spots_count > 0 || floor.storage_count > 0) return floor.level
  }
  return null
}

/**
 * Маска телефона
 * @param phone
 * @returns {string}
 */
export const maskPhone = (phone = "") => {
  const x = phone
    .replace(/\D/g, "")
    .match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
  return `+${x[1]} (${x[2]}) ${x[3]}-${x[4]}-${x[5]}`;
};

export const mergeRoomInfo = (commonRoomInfo, roomTypes) => {
  if(!commonRoomInfo) return null;

  const result = []

  roomTypes.forEach((roomType) => {
    let roomsCommonInfo = {}
    if (roomType.rooms_count === 0) {
      roomsCommonInfo = commonRoomInfo.find((info) => info.rooms === 1)
    } else {
      roomsCommonInfo = commonRoomInfo.find(
        (info) => info.rooms === roomType.rooms_count
      )
    }

    if (roomsCommonInfo) {
      const mergedRoomType = {
        ...roomType,
        rooms_common_info: roomsCommonInfo,
      }
      result.push(mergedRoomType)
    }
  })

  return result
}